import React from "react";
import PlesyEl from "../components/plesyEl";


const Plesy = () => {

  return (
    <PlesyEl />
  )
}

export default Plesy
