import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ImageFadeIn from "react-image-fade-in";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";
import VideoSkelet from "../components/videoSkelet";
import PlesySkelet from "../components/fotoPlesySkelet";
import { Link } from "gatsby";
import Layout from "../components/layout";

import poster from '../../static/images/plesyPoster.webp'

const PlesyEl = () => {




    const photoList = [
        {
            id: 1,
            style: "col-4",
            preview: "plesy/n/1.webp",
            full: "plesy/f/1.jpg",
            alt: "plesový kameraman",
        },
        {
            id: 2,
            style: "col-4",
            preview: "plesy/n/2.webp",
            full: "plesy/f/2.jpg",
            alt: "kameramanské duo",
        },
        {
            id: 3,
            style: "col-4",
            preview: "plesy/n/3.webp",
            full: "plesy/f/3.jpg",
            alt: "profi kamera",
        },
        {
            id: 4,
            style: "col-12",
            preview: "plesy/n/4.webp",
            full: "plesy/f/4.jpg",
            alt: "matirutní ples Jičín",
        },
        {
            id: 5,
            style: "col-12",
            preview: "plesy/n/5.webp",
            full: "plesy/f/5.jpg",
            alt: "maturitní kameraman Jičín",
        },
        {
            id: 6,
            style: "col-12",
            preview: "plesy/n/6.webp",
            full: "plesy/f/6.jpg",
            alt: "plesový kameraman nová paka",
        },
        {
            id: 7,
            style: "col-12",
            preview: "plesy/n/7.webp",
            full: "plesy/f/7.jpg",
            alt: "matrutní kameraman praha",
        },
        {
            id: 8,
            style: "col-12",
            preview: "plesy/n/8.webp",
            full: "plesy/f/8.jpg",
            alt: "maturitní fotograf a kameraman poděbrady",
        },
        {
            id: 9,
            style: "col-12",
            preview: "plesy/n/9.webp",
            full: "plesy/f/9.jpg",
            alt: "kamera podělbrady",
        },
        {
            id: 10,
            style: "col-12",
            preview: "plesy/n/10.webp",
            full: "plesy/f/10.jpg",
            alt: "maturtní video Praha",
        },
        {
            id: 11,
            style: "col-12",
            preview: "plesy/n/11.webp",
            full: "plesy/f/11.jpg",
            alt: "matritní večírek kameraman",
        },
        {
            id: 12,
            style: "col-4",
            preview: "plesy/n/12.webp",
            full: "plesy/f/12.jpg",
            alt: "maturitní klip Jičín",
        },
        {
            id: 13,
            style: "col-4",
            preview: "plesy/n/13.webp",
            full: "plesy/f/13.jpg",
            alt: "plesový klip kameraman východočeský kraj",
        },
        {
            id: 14,
            style: "col-4",
            preview: "plesy/n/14.webp",
            full: "plesy/f/14.jpg",
            alt: "porfolio fotka plesů",
        }
    ];


    const videos = [
        {
            id: 1,
            style: "col-12 col-lg-6",
            preview: "plesy/video1.webp",
            full: "https://www.youtube.com/watch?v=h2Oeems2m0Q",
            alt: "porfolio fotka plesů",
            desc: "Půlnoční překvapení | SOŠPg Nová Paka",
        },
        {
            id: 2,
            style: "col-12 col-lg-6",
            preview: "plesy/video2.webp",
            full: "https://www.youtube.com/watch?v=apQKqAmxSIs",
            alt: "porfolio fotka plesů",
            desc: "Maturitní ples | SOŠPg Nová Paka",
        }
    ];




    const helmetElement = (
        <Helmet>
            <title>Plesy | Martin Petrovický | Foto a video</title>
            <meta
                name="description"
                content="Ukázka plesových fotografií a videí."
            />
            <meta
                name="keywords"
                content="Ples, Maturita, fotograf, kameraman, maturitní video, maturitní fotograf, plesový kameraman, maturitní kameraman, prom"
            />
            <meta name="author" content="Martin Petrovický" />
        </Helmet>
    );


    const togglePack = (packId) => {
        var paragraph = document.querySelector(`.pp${packId}`);
        paragraph.classList.toggle("expand-pack");
        var icon = document.querySelector(`.icon${packId}`);
        icon.classList.toggle("icon-expand");
    };

    const handleImageClick = () => {
        document
            .getElementById("svatby_header")
            .scrollIntoView({ behavior: "smooth", block: "start" });
    };

    useEffect(() => {
        const path = window.location.pathname
        if (path.includes('Video')) {
            scroolToElement('prom_video')
        }
    }, [])

    const scroolToElement = (id) => {
        const violation = document.getElementById(id);
        // const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        if (violation !== null) {
            setTimeout(() => {
                violation.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 200)

        }
    }

    return (
        <Layout>
            <main>
                {helmetElement}
                <div className="div-svatby-poster">
                    <div className="rounded svatby-poster-banner text-center text-white py-5">
                        <Fade delay={200} duration={1500}>
                            <h3 className="mx-2 mx-lg-5 mb-3">
                                ZOPAKUJTE SI VÁŠ NEJLEPŠÍ VEČÍREK ŽIVOTA TŘEBA 100X DOKOLA
                            </h3>
                        </Fade>
                        {/* <Fade delay={500} duration={1500}>
                            <p>Foto a video z vašěho </p>
                        </Fade> */}
                    </div>
                    <div className="scroll-arrow d-block d-md-none" onClick={handleImageClick}>
                        <div className="chevron"></div>
                        <div className="chevron"></div>
                        <div className="chevron"></div>
                    </div>

                    <ImageFadeIn
                        opacityTransition={1.5}
                        src={poster}
                        className="svatby-poster-img d-none d-lg-block"
                        alt="Uvodní foto na pozadí"
                    />
                    <ImageFadeIn
                        opacityTransition={1.5}
                        src={poster}
                        style={{ marginLeft: '-330px' }}
                        className="svatby-poster-img d-block d-lg-none"
                        alt="Uvodní foto na pozadí"
                    />
                </div>
                <div id="svatby_header"></div>
                <div className="container">
                    <div className="text-center h1 mt-5 mb-4 ">Maturitní Foto a Video</div>

                    <PlesySkelet photoList={photoList} />

                    <span id="prom_video" >
                        <VideoSkelet videos={videos} />
                    </span>


                    <div className="row text-center py-5">
                        <div className="col-12 col-lg-6 text-left">
                            <h2 className="mb-3 ml-0 ml-lg-3">STÁLE NEMÁTE KAMERAMANA NA VÁŠ MATURIŤÁK?</h2>
                            <ul className="list-dots">
                                <li>Tahle párty už se nebude opakovat! Prožijte ji díky nám třeba 100x znovu.</li>
                                <li className="my-2">Dojedeme kdekoliv po ČR</li>
                                <li>Točíme ve dvou na dvě kamery</li>
                                <li className="my-2">Video z vašeho půlnočního překvapení do 24 hodin online!</li>
                            </ul>
                        </div>

                        <div className="col-12 col-lg-6 mt-5 mt-md-0">
                            <h2 className="mb-3">CO ZA TO?</h2>
                            <div>
                                <h6 className="cursor-pointer" onClick={() => togglePack(5)}>
                                    MATURITNÍ FOTKY
                                    <FontAwesomeIcon
                                        className="ml-2 align-middle icon icon5"
                                        icon={faChevronDown}
                                    />
                                </h6>
                                <p className="price-pack pp5  p-0">
                                    <br />
                                    Focení celého večeru od příprav po půlnoční překvapení.
                                    <br />
                                    <br />
                                    Obdržíte cca 300 upravených fotek v elektronické podobě.
                                    <br />
                                    <br />
                                    <b>CENA: 12 000 Kč</b>
                                </p>
                            </div>
                            <div>
                                <h6 className="cursor-pointer" onClick={() => togglePack(6)}>
                                    MATURITNÍ VIDEO
                                    <FontAwesomeIcon
                                        className="ml-2 align-middle icon icon6"
                                        icon={faChevronDown}
                                    />
                                </h6>
                                <p className="price-pack pp6  p-0">
                                    <br />
                                    Natáčení celého večeru od příprav po půlnoční překvapení.
                                    <br />
                                    <br />
                                    Obdržíte maturtní videklip a video z půlnočního překvapení.

                                    <br />
                                    <br />
                                    <b>CENA: 21 000 Kč</b>
                                </p>
                            </div>
                            <Link to="/kontakt">
                                <div className="btn btn-svatby rounded-0 border border-dark border-end-0 border-start-0 mt-3 mb-5">
                                    Napište mi
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default PlesyEl
